import { useEffect, useRef } from "react";
// import { onLoadScrollHandler } from "../../util/helpers";
import Icon, { Download } from "../Icons";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const FullWidthTwoCol = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  /* const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  } */

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });

    /* // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler); */
  }, []);

  const downloads = props.data.downloads && props.data.downloads.length > 0 && props.data.downloads.map( (download: any, index: any) => 
    (
      <p key={index} className="mb-5 last:mb-0">
        <a 
          href={download.file.url} 
          target="_blank" 
          rel="noreferrer" 
          className="inline-block leading-5 font-bold group"
        >
          <span className="text-white inline-block w-60 mr-4 group-hover:underline" dangerouslySetInnerHTML={{ __html: download.title }} />
          <Icon type={<Download color="#ffffff" />} className="group-hover:hidden" />
          <Icon type={<Download color="#ffffff" />} className="hidden group-hover:inline-block" />
        </a>
      </p>
    )
  );

  return (
    <section ref={sectionEl} className="bg-blue content__full-width-two-col">
      <div className="text-blue px-12 py-9 lg:grid lg:grid-cols-2 lg:py-0 lg:px-0">
        <div className="animate fade-in ease-in-out" style={{
            background: `url('${props.data.image[0]}') center / cover no-repeat`,
          }}>
          <img src={props.data.image[0]} alt="" className="opacity-0" />
        </div>
        <div className="text-sm lg:p-12 lg:pt-16 lg:flex lg:items-center min1200px:px-30">
          <div className="animate fade-in-grow ease-in-out mt-10 lg:max-w-[480px] lg:mt-0">
            <strong className="block leading-tight mb-6 text-green text-2xl">{ props.data.title }</strong>
            <strong className="block text-sm leading-6 text-white font-bold mb-6">{ props.data.subtitle }</strong>
            <p className="text-sm leading-6 text-white font-medium">{ props.data.description }</p>
            <div className="mt-14">
              {downloads}
            </div>
            {/* {props.data.downloads_disclaimer &&
              <p className="w-full text-xxs leading-[1.4] mt-5">
                {props.data.downloads_disclaimer}
              </p>
            } */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default FullWidthTwoCol;