import { useEffect, useRef } from "react";
import Icon, { Phone, Mail } from "../Icons";
import { onLoadScrollHandler } from "../../util/helpers";

const GetInTouchTile = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section ref={sectionEl} className="content__get-in-touch-tile" style={{
      background: props.data.bg_colour ? props.data.bg_colour : '#fff',
    }}>
      <div className="animate slide-from-top ease-in-out text-blue font-sans mx-auto max-w-4xl px-12 py-11 md:py-20">
        <div className="text-center">
          <strong className="block text-xs md:text-lg">{ props.data.subtitle }</strong>
          <h2 className="font-bold text-2xl md:text-4xl">{ props.data.title }</h2>
          <p className="text-xs md:text-sm font-medium mt-2">{ props.data.description }</p>
        </div>
        <div className="mt-7 md:flex text-center md:text-left justify-center">
          <div className="mb-4 md:mb-0 md:mr-8">
            <Icon type={<Phone />} className="relative top-2" />
            <span className="font-bold text-base ml-4 leading-none"><a href={'tel:'+ props.data.phone}>{ props.data.phone }</a></span>
          </div>
          <div>
            <Icon type={<Mail />} className="relative top-2" />
            <span className="font-bold text-base ml-4 leading-none"><a href={'mailto:'+ props.data.email}>{ props.data.email }</a></span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GetInTouchTile;