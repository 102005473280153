import { useEffect, useRef } from "react";
// import { onLoadScrollHandler } from "../../util/helpers";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const TwoColFacts = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  /* const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  } */

  useEffect(() => {
    ScrollTrigger.create({
      trigger: sectionEl.current,
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });

    /* // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler); */
  }, []);

  const leftColumn = props.data.left_column && props.data.left_column.length > 0 && props.data.left_column.map( (info: any, index: any) => 
    (
      <div key={index} className="mb-8 md:mb-9">
        { info.subtitle && <em className="not-italic font-bold text-sm md:text-lg block">{ info.subtitle }</em> }
        { info.title &&  <strong className="text-2xl md:text-4xl leading-snug block">{ info.title }</strong> }
        { info.description && <div className="text-xs leading-5 md:text-sm font-medium pt-1.5 pb-0 md:leading-breezy" dangerouslySetInnerHTML={{ __html: info.description }} /> }
      </div>
    )
  );

  const rightColumn = props.data.right_column && props.data.right_column.length > 0 && props.data.right_column.map( (info: any, index: any) => 
    (
      <div key={index} className="mb-8 md:mb-9">
        { info.subtitle && <em className="not-italic font-bold text-sm md:text-lg block">{ info.subtitle }</em> }
        { info.title &&  <strong className="text-2xl md:text-4xl leading-snug block">{ info.title }</strong> }
        { info.description && <div className="text-xs leading-5 md:text-sm font-medium pt-1.5 pb-0 md:leading-breezy" dangerouslySetInnerHTML={{ __html: info.description }} /> }
      </div>
    )
  );

  return (
    <section ref={sectionEl} className="bg-white pt-12 pb-12 md:pt-30 md:pb-22 content__two-col-facts">
      <h2 className="font-bold text-2xl md:text-4xl leading-snug text-blue mx-auto w-full max-w-5xl px-12 mb-8 md:mb-11">{ props.data.heading }</h2>
      <div className="text-blue md:grid md:grid-cols-2 mx-auto w-full max-w-5xl px-12">
        <div className="animate slide-from-left ease-out md:mr-20">{ leftColumn }</div>
        <div className="animate slide-from-right ease-out right-col">{ rightColumn }</div>
      </div>
    </section>
  );
}

export default TwoColFacts;