import { useEffect, useRef } from "react";
// import { onLoadScrollHandler } from "../../util/helpers";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const HeroSectionFullwidth = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  /* const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  } */

  useEffect(() => {
    /* gsap.fromTo(
      sectionEl.current,
      {
        // opacity: 0,
        // y: 250
      },
      {
        // opacity: 1,
        // y: 0,
        scrollTrigger: {
          trigger: sectionEl.current,
          start: "top center",
          // start: "center center",
          end: "bottom top",
          // scrub: true
          toggleClass: "on-screen",
          // toggleActions: "play reverse none none",
          // markers: true,
          once: true,
        }
      }
    ); */

    ScrollTrigger.create({
      trigger: sectionEl.current,
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });

    // scrollHandler();
    /* onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler); */
  }, []);

  const columns = props.data && props.data.description && props.data.description.length > 0 && props.data.description.map( (column: any, index: any) => 
    (
      <p key={index} className="text-white mx-auto" dangerouslySetInnerHTML={{ __html: column }} />
    )
  );
  
  return (
    <section ref={sectionEl} className="bg-blue content__hero-section-fullwidth">
      <div className="animate fade-in w-full h-80 md:h-120 lg:h-171 bg-fullwidth" style={{
        backgroundImage: `url('${props.data.image.url}')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}>
        <img className="w-full max-h-full invisible" src={ props.data.image.url } alt="" />
      </div>
      <div className="animate fade-in-grow pt-11 pb-11 md:pt-20 md:pb-24">
        <h1 className="text-green text-2xl leading-tight text-center max-w-5xl px-6 mx-auto mb-3.5 md:text-4xl font-bold md:leading-normal " dangerouslySetInnerHTML={{ __html: props.data.title }} />
        {
          props.data.description.length === 1 ?
          <div className="flex gap-10 max-w-5xl px-8 mx-auto text-center font-bold text-sm md:text-lg">
            { columns }
          </div>
          :
          <div id={ props.data.field_id } className="md:flex gap-10 max-w-full lg:max-w-[1000px] text-sm mx-auto leading-6 px-6 lg:px-0 hero-section-fullwidth__col-wrap">
            { columns }
          </div>
        }
      </div>
    </section>
  );
}

export default HeroSectionFullwidth;