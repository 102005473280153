import { useEffect, useRef } from "react";
import { onLoadScrollHandler } from "../../util/helpers";

const FullwidthImage = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  }

  useEffect(() => {
    // scrollHandler();
    onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section ref={sectionEl} className="content__fullwidth-image" id={ props.data.field_id }>
      <div className="animate fade-in w-full h-80 lg:h-160 2xl:h-[33.33vw] bg-fullwidth" style={{
        backgroundImage: `url('${props.data.image.url}')`,
        backgroundSize: 'cover',
      }}>
        <img className="w-full max-h-full invisible" src={ props.data.image.url } alt="" />
      </div>
    </section>
  );
}

export default FullwidthImage;