import { useEffect, useRef } from "react";
import ButtonLink from "../Button/ButtonLink";
// import { onLoadScrollHandler } from "../../util/helpers";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const HeroSectionTwoCol = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  /* const scrollHandler = () => {
    let bounding = sectionEl.current?.getBoundingClientRect();

    if( bounding && bounding.top <= window.innerHeight && bounding.bottom > 0 ) {
      // sectionEl.current?.classList.add('on-screen');
      if( bounding.top < window.innerHeight-(bounding.height/2) ) {
        sectionEl.current?.classList.add('on-screen');
      }
      // else {
      //   sectionEl.current?.classList.remove('on-screen');
      // }
    }
    // else {
    //   sectionEl.current?.classList.remove('on-screen');
    // }
  } */

  useEffect(() => {
    /* gsap.fromTo(
      sectionEl.current,
      {
        // opacity: 0,
        // y: 250
      },
      {
        // opacity: 1,
        // y: 0,
        scrollTrigger: {
          trigger: sectionEl.current,
          start: "top center",
          // start: "center center",
          end: "bottom top",
          // scrub: true
          toggleClass: "on-screen",
          // toggleActions: "play reverse none none",
          // markers: true,
          once: true,
        }
      }
    ); */

    ScrollTrigger.create({
      trigger: sectionEl.current,
      start: "top center",
      end: "bottom top",
      toggleClass: "on-screen",
      once: true,
      // markers: true,
    });

    // scrollHandler();
    /* onLoadScrollHandler(sectionEl);
    window.addEventListener('scroll', scrollHandler);
    // window.scrollTo(0, 1);
    // window.scrollTo(0, 0);
    // triggerScroll();
    return () => window.removeEventListener('scroll', scrollHandler); */
  }, []);
  
  return (
    <section ref={sectionEl} className="bg-white hero-section-two-col">
      <div className="w-full lg:flex">
        <div className="lg:w-1/2" style={{
        backgroundImage: `url('${props.data.image.url}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
          <img className="invisible" src={ props.data.image.url } alt="" />
        </div>
        <div className="lg:w-1/2 pt-12 pb-12 md:pt-45 md:pb-50 px-12 max-w-2xl mx-auto lg:max-w-full md:px-[4vw] lg:px-[5vw] 2xl:px-[7.8vw]">
          <h1 className="animate slide-from-left ease-in-out h2">
            <span className="text-blue2 block leading-tight pb-1.5 max-w-xl mx-auto md:mx-0">{ props.data.title }</span>
          </h1>
          <div className="animate slide-from-left ease-in-out text-base font-semibold text-blue2 mb-11 md:mb-22 hero-section-two-col__desc" dangerouslySetInnerHTML={{ __html: props.data.description }} />
          <div className="text-blue2 animate slide-from-left ease-in-out delay-300">
            <strong className="block text-lg mb-3.5">{ props.data.stats.title }</strong>
            <div className="flex">
              <div className="min1310px:flex items-end h-fit">
                <span className="block leading-none text-4xl md:text-5.5xl font-medium mr-3">{ props.data.stats.stat_1 }</span>
                <span className="block text-xs md:text-sm font-semibold" dangerouslySetInnerHTML={{ __html: props.data.stats.stat_1_description }} />
              </div>
              <span className="block w-[2px] h-12.5 bg-green mx-9" />
              <div className="min1310px:flex items-end h-fit">
                <span className="block leading-none text-4xl md:text-5.5xl font-medium mr-3">{ props.data.stats.stat_2 }</span>
                <span className="block text-xs md:text-sm font-semibold" dangerouslySetInnerHTML={{ __html: props.data.stats.stat_2_description }} />
              </div>
            </div>
          </div>
          <div className="animate slide-from-left ease-in-out delay-300 mt-10 md:mt-20">
            <ButtonLink type="primary" link={'/'+props.data.button.url.post_name} className="w-72 md:w-80 md:h-16 md:text-xl md:rounded-32px md:py-4">
              { props.data.button.title }
            </ButtonLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSectionTwoCol;